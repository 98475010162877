import React, { Component } from 'react';
import photo from './profile_photo.jpg'
import jammer from './jammer_icon.png'
import molasses from './molasses_icon.png'
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        
        <div className="Column">
        
          <div>
             <img src={photo} width={200} height={200} style={{ borderRadius: 100, objectFit: 'cover', objectPosition: 'center' }} alt="portrait"/>
          </div>
          
          <div>
            <h3 style={{ textAlign: 'left', marginBottom: -8, marginLeft: 2 }}>Sam Ballantyne writes</h3>
            <h1 style={{ fontFamily: 'Monoton', fontSize: 50, margin: -8 }}>software</h1>
            <h3 style={{ textAlign: 'right', marginTop: -8, marginRight: 2 }}>in Brooklyn</h3>
          </div>
          
          <div style={{ textAlign: 'left' }}>
            
            <h5 style={{ textTransform: 'uppercase', marginTop: 40, marginBottom: 0, color: 'grey' }}>Backstory</h5>
            
            <p>
              Sam is a technology industry leader and expert, having built and scaled product for leading brands including Disney and Cirque de Soleil. He’s written fraud-detection code used by Facebook and CrowdStrike. His independent work has been covered by CNN, Mashable, PC Mag, Maxim and others.
            </p>
            
            <p>
              These days Sam is working at Keezy Corp. in Brooklyn on Jammer, a popular music app.
            </p>
            
            <div style={{ textAlign: 'center' }}>
              <a href="https://itunes.apple.com/us/app/jammer/id1210112367?mt=8">
                <img src={jammer} width={60} alt="jammer icon"/>
              </a>
            </div>
            
            <p>
              In a previous life, Sam was a classical musician and a cook. He holds a Bachelors Degree in Music from Manhattan School of Music and a certificate or something from Le Cordon Bleu. He still cooks often and maintains a grocery list app with his wife.
            </p>
            
            <div style={{ textAlign: 'center' }}>
              <a href="https://itunes.apple.com/us/app/molasses/id1222425162?mt=8">
                <img src={molasses} width={60} alt="molasses icon"/>
              </a>
            </div>
            
            <p>
              If you’re working on something interesting, drop him a line. He responds to friendly emails sent to <a href="mailto://hello@samballantyne.com">hello@samballantyne.com</a>.
            </p>
            
            <p style={{ textAlign: 'center' }}><span role="img" aria-label="tada">🎉</span></p>
            
          </div>
          
        </div>
      </div>
    );
  }
}

export default App;
